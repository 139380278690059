@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
select,
textarea {
  font-size: 16px !important;
}

html {
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth !important;

  @media (prefers-reduced-motion: reduce) {
    scroll-behavior: auto;
  }
}
html,
body {
  overflow-x: hidden;
}

.sr-only-fixed {
  position: fixed !important;
  width: 1 px !important;
  height: 1 px !important;
  padding: 0 !important;
  margin: -1 px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border-width: 0 !important;
}

.grid-container,
.usa-nav-container,
.usa-banner__inner {
  max-width: 76rem !important;
}

.usa-hero__callout {
  background-color: #162e51f0 !important;
}

.usa-nav {
  width: 100%;
}

ul.usa-nav__primary.usa-accordion {
  align-items: center !important;
}

.with-links > a {
  text-decoration: underline;
}

.with-links > p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.usa-skipnav {
  text-decoration: none !important;
}

.usa-skipnav:visited {
  color: #005ea2;
  text-decoration: none !important;
}

.usa-nav__link {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.usa-nav__submenu-item {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.usa-nav__primary button {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.usa-menu-btn {
  margin-top: 1px;
}

.usa-menu-btn:not(:first-of-type) {
  border-left: 1px solid #00000029 !important;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #2491ff;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 0.25rem;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #2491ff, 0 0 5px #2491ff;
  opacity: 0.5;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
/* #nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #2491ff;
  border-left-color: #2491ff;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */
